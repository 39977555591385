import gql from "graphql-tag";


// Page category

const WAVE = gql `
fragment Wave on MarkPageContentDynamicZone {
  ... on ComponentGlobalWave {
    __typename
    items {
      title
      discription
      link
      link_text
    }
  }
}
`;

const IMGS_RANDOM = gql `
fragment ImgsRandom on MarkPageContentDynamicZone {
  ... on ComponentGlobalImgsRandom {
    __typename
    title
    discription
    img1 {
      data {
        attributes {
          url
        }
      }
    }
    img2 {
      data {
        attributes {
          url
        }
      }
    }
    img3 {
      data {
        attributes {
          url
        }
      }
    }
  }
}
`;

const DOUBLE_BANNER = gql `
fragment DoubleBanner on MarkPageContentDynamicZone {
  ... on ComponentGlobalDoubleBanner {
    __typename
    title
    items {
      title
      image {
        data {
          attributes {
            url
          }
        }
      }
    }
  }
}
`

const SIMPLE_BLOC = gql `
fragment SimpleBloc2 on MarkPageContentDynamicZone {
  ... on ComponentGlobalSimpleBloc2 {
    __typename
    title
    discription
  }
}
`

const BANNER = gql `
fragment Banner4 on MarkPageContentDynamicZone {
  ... on ComponentGlobalBannerItem {
    __typename
    title
    image {
      data {
        attributes {
          url
        }
      }
    }
    link
    link_text
  }
}
`

export const MarkPage = gql `
${WAVE}
${IMGS_RANDOM}
${DOUBLE_BANNER}
${SIMPLE_BLOC}
${BANNER}
query pageMark($lang: I18NLocaleCode) {
  markPage(locale:$lang) {
    data {
      attributes {
        title
        top_image {
          data {
            attributes {
              url
            }
          }
        }
        content {
          ...Wave
          ...ImgsRandom
          ...DoubleBanner
          ...SimpleBloc2
          ...Banner4
        }
      }
    }
  }
}
`
